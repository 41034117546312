a {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

a, a:hover {
    text-decoration: none !important;
}

.footer-59391 {
    border-top: 1px solid #272727;
    background-color: #272727;
    font-family: "Poppins", sans-serif;
    padding: 1rem 0;
}

.footer-59391 .site-logo a {
    font-size: 24px;
    background: linear-gradient( #F45C43, #EB3349);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.footer-59391 .nav-links li {
    display: inline-block;
}

.footer-59391 .nav-links li a {
    color: #D9DBE1;
    padding: 10px;
}

.footer-59391 .nav-links li a:hover {
    background: linear-gradient( #F45C43, #EB3349);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.footer-59391 .nav-links.nav-left li:first-child {
    padding-left: 0;
}

.footer-59391 .nav-links.nav-right li:last-child {
    padding-right: 0;
}

@media (max-width: 768px) {
    .footer-59391 .site-logo a {
        display: none;
    }
    .footer-59391 .nav-links li {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

#footer-bottom-align {
    position: absolute;
    bottom: 0;
    width: 100%;
}