.toolbar_navigation-items a {
    color: white;
    text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
    color: grey;
}

@media (max-width: 768px) {
    .toolbar_navigation-items {
        display: none;
    }
}

@media (min-width: 769px) {
    .toolbar__toggle-button {
        display: none;
    }
}